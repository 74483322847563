import "dreamland";
import { articleCSS } from "./CommonCSS";

export const FullArticle: Component<{}, {}> = function () {
  return (
    <article class={articleCSS}>
      <IntroSmall />
      <About />
      <Contact />
      <SiteMap />
    </article>
  );
};

export const Intro: Component<{}, {}> = function () {

  this.css = `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  img {
    height: auto;
    width: min(20rem, 50%);
    align-self: flex-start;
    justify-self: flex-end;
    float: left;
    margin-right: 2rem;
  }

  @media (orientation: portrait) {
    #kawaii {
      display: none; /* TODO: Figure something out */
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    #kawaii {
      display: none;
    }
  }
  `

  return (
    <div>
      <div>
        <IntroSmall />
        <br></br>
        <About />
      </div>
      <img id="kawaii" src="/kawaii.svg" alt="Vtuber-style logo" height={1000} width={1000} />
    </div>
  );
};

export const IntroSmall: Component<{}, {}> = function () {
  return (
    <div>
      <h1>Hi 👋</h1>
      <p>
        I'm Deva, a developer from India, and I sometimes do/make cool/lame things.
      </p>
      <p>
        <br></br>
        As for engineering, I'm interested in:
        <ul>
          <li>Hardware (building robots 'n stuff!)</li>
          <li>Webdev (kinda)</li>
          <li>Backend </li>
          <li>And a little bit of security research</li>
        </ul>
      </p>
    </div>
  )
}

export const About: Component<{}, {}> = function () {
  return (
    <div>
      <h2>Some more about me</h2>
      <ul>
        <li>
          I know the following programming languages (well):
          <ul>
            <li>Python (the GOAT)</li>
            <li>JavaScript</li>
            <li>C/C++ (kinda)</li>
            <li>Bash</li>
          </ul>
        </li>
        <li>I use Arch (btw)</li>
      </ul>
    </div>
  );
};

export const Contact: Component<{}, {}> = function () {
  return (
    <div>
      <h2>Get in touch</h2>
      <ul>
        <li>
          <a href="https://wetdry.world/@coder" target="blank" rel="me">
            Fediverse
          </a>
          &nbsp;(@coder@wetdry.world)
        </li>
        <li>
          <a href="https://reddit.com/u/DALEK_77" target="blank" rel="me">
            Reddit
          </a>
        </li>
        <li>
          <a href="https://twitter.com/coderrrrrsite" target="blank" rel="me">
            Twitter
          </a>
        </li>
        <li>
          <a href="https://github.com/turbomaster95" target="blank" rel="me">
            GitHub
          </a>
        </li>
        <li>
          <a href="mailto:admin@coderrrrr.site">Email</a> (admin@coderrrrr.site)
          <ul>
            <li>I might be slow to respond, I don't check my email often.</li>
          </ul>
        </li>
        <li>
          Discord
          <ul>
            <li>
              @coderrrrr
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export const SiteMap: Component<{}, {}> = function () {
  return (
    <div>
      <h2>Other things on this site</h2>
      <ul>
        <li>
          <a href="https://bomberfish.ca/games">/games</a>
        </li>
        <li>
          <a href="./coolthings/index.html">/coolthings</a>
        </li>
        <li>
          <a href="./tools/index.html">/tools</a>
        </li>
      </ul>
    </div>
  );
};

export const DesignPhilosophy: Component<{}, {}> = function () {
  return (
    <div>
      <h3>This is my personal website!</h3>
      <h6>Copied from <a href="https://bomberfish.ca">here!</a></h6>
    </div>
  );
};
